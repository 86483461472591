<template>
  <div>
    <div class="top-bar">
      <div class="top-bar-text" v-if="showText">{{ currentText }}</div>
    </div>
    <header>
      <div class="header-container">
        <div class="hamburger-menu" @click="toggleMobileMenu">
          <i class="fa fa-bars"></i>
        </div>
        <div class="logo">
          <a href="/"><img src="../assets/images/logo.webp" alt="OHUP Logo" width="35px" height="35px" /></a>
        </div>
        <nav :class="{ 'mobile-menu-open': isMobileMenuOpen }">
  <ul>
    <li><a href="/about">Hakkımızda</a></li>
    <li>
<a href="/products" @click.prevent="toggleDropdown('shop')" class="shop-link">
  Alışveriş  <i class="fa" :class="{'fa-angle-down': !showShopDropdown, 'fa-angle-up': showShopDropdown}"></i>
</a>
<noscript>
  <a href="/products" class="shop-link">Alışveriş</a>
</noscript>

      <ul :class="{'dropdown-menu': true, show: showShopDropdown}">
        <li><a href="/products/gym-chair-artist">Gym Chair Artist</a></li>
        <li><a href="/products/gym-chair">Gym Chair</a></li>
        <li><a href="/products/gym-chair-lite">Gym Chair Lite</a></li>

      </ul>
    </li>
 <!--   <li>
      <a href="javascript:void(0)" @click="toggleDropdown('products')">
        Ürünler  <i class="fa" :class="{'fa-angle-down': !showProductsDropdown, 'fa-angle-up': showProductsDropdown}"></i>
      </a>
      <ul :class="{'dropdown-menu': true, show: showProductsDropdown}">
        <li><a href="/products">Ürünler</a></li>
        <li><a href="/product-comparison">Ürün Karşılaştırması</a></li>
      </ul>
    </li>
    <li>
      <a href="javascript:void(0)" @click="toggleDropdown('corporate')">
        Neden OHUP? <i class="fa" :class="{'fa-angle-down': !showCorporateDropdown, 'fa-angle-up': showCorporateDropdown}"></i>
      </a>
      <ul :class="{'dropdown-menu': true, show: showCorporateDropdown}">
        <li><a href="/about">Hakkımızda</a></li>
        <li><a href="/product-comparison">Karşılaştırma</a></li>
        <li><a href="/designers">Tasarımcılar</a></li>
        <li><a href="/blog">Blog</a></li>
      </ul>
    </li>-->

    <li><a href="/designers">Tasarımcılar</a></li>

    <li><a href="/product-design">İlham</a></li>
    <li><a href="/exercises">Egzersizler</a></li>

  </ul>
</nav>

        <div class="icons">
          <a href="/search" aria-label="search" @click.prevent="toggleSearch"><i class="fa fa-search"></i></a>
          <a href="/cart" @click.prevent="toggleCart" class="cart-link">
  <i class="fa fa-shopping-cart"></i>
  <span class="cart-count">{{ cartCount }}</span>
</a>
<noscript>
  <a href="/cart" class="cart-link">
    <i class="fa fa-shopping-cart"></i>
    <span class="cart-count">{{ cartCount }}</span>
  </a>
</noscript>
        </div>
      </div>

    <!-- Modern Search Bar -->
    <transition name="fade">
  <div class="search-overlay" v-if="isSearchOpen" @click="closeSearch">
    <div class="search-container" @click.stop>
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Ürün veya kategori ara..."
        @keyup.enter="performSearch"
        autofocus
      />
      <button @click="closeSearch" class="close-btn">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</transition>


    </header>

    <transition name="cart-sidebar">
      <div class="cart-sidebar" :class="{ 'cart-sidebar-open': isCartOpen, 'cart-sidebar-closed': !isCartOpen }" @click.stop ref="cartSidebar">
        <div class="cart-header">
          <h2>Sepetim</h2>
          <button @click="toggleCart">Kapat</button>
        </div>
        <div class="cart-contents">
          <template v-if="cart.length === 0">
            <div class="cart-content">
  <i class="fa fa-shopping-bag cart-empty-icon"></i>
  <p class="cart-empty-text">Sepetiniz boş görünüyor</p>
  <p class="cart-suggestion">Favori ürünlerinizi sepete ekleyin!</p>
  <button class="start-shopping"><a href="/products">Alışverişe Başla</a></button>
</div>
          </template>
          <template v-else>
            <div class="cart-items">
              <div v-for="(item, index) in cart" :key="index" class="cart-item">
                <img :src="item.image" alt="Product Image" />
                <div class="cart-item-info">
                  <h3>{{ item.name }}</h3>
                  <p>{{ item.color }}</p>
                  <p>{{ item.price }} ₺</p>
                  <div class="quantity-selector">
                    <button @click="decreaseQuantity(item)">-</button>
                    <input type="number" v-model.number="item.quantity" min="1" @change="updateCart(item)" />
                    <button @click="increaseQuantity(item)">+</button>
                  </div>
                </div>
                <i class="fa fa-trash remove-icon" @click="removeFromCart(item)"></i>
              </div>
            </div>
            <div class="cart-summary">
  <p class="subtotal">Ara Toplam</p>
  <p class="total-price">{{ formattedTotalPrice }}</p>
  <p class="tax-info">Vergiler ve gönderim bedelleri ödeme sırasında hesaplanır.</p>
  <div class="buttons">
  <button class="view-cart-button"> <a href="/cart">Sepeti Görüntüle</a></button>
  <button class="checkout-button"> <a href="/checkout">Ödeme</a></button>
</div>
</div>


          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '../EventBus.js';

export default {
  name: 'Header',
  data() {
    return {
      texts: ["OHUP'a hoş geldiniz", "Peşin Fiyata 6 Taksite Kadar", "Topluluğumuza Katılın"],
      currentIndex: 0,
      currentText: "",
      showText: true,
      isCartOpen: false,
      isMobileMenuOpen: false,
      cart: [],
      cartCount: 0,
      showCorporateDropdown: false,
      showShopDropdown: false,
      showSupportDropdown: false,
      showProductsDropdown: false,
      isSearchOpen: false,
      searchQuery: ''
      
    };
  },
  computed: {
  totalPrice() {
    return this.cart.reduce((total, item) => {
      const price = parseFloat(item.price.replace(/[^0-9.-]+/g, ''));
      return total + (price * item.quantity);
    }, 0);
  },
  formattedTotalPrice() {
    return this.totalPrice.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
  }
},
  mounted() {
    this.currentText = this.texts[this.currentIndex];
    setInterval(this.changeText, 4000);
    document.addEventListener('click', this.closeCartOnClickOutside);
    this.loadCart();
    EventBus.on('update-cart-count', this.loadCart);
    document.addEventListener('click', this.handleClickOutside);
    this.fetchProductsAndUpdateCart();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeCartOnClickOutside);
    EventBus.off('update-cart-count', this.loadCart);
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },
    closeSearch() {
      this.isSearchOpen = false;
    },
    performSearch() {
      if (this.searchQuery.trim()) {
        // Arama sonuç sayfasına yönlendir ve arama kelimesini query parametre olarak gönder
        this.$router.push({
          name: 'SearchResults',
          query: { q: this.searchQuery }
        });
        this.closeSearch();
      }
    },
    changeText() {
      this.showText = false;
      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.texts.length;
        this.currentText = this.texts[this.currentIndex];
        this.showText = true;
      }, 500);
    },
    closeCartOnClickOutside(event) {
    const cartElement = this.$refs.cartSidebar;
    if (cartElement && !cartElement.contains(event.target)) {
      this.isCartOpen = false;
    }
  },
  async fetchProductsAndUpdateCart() {
  try {
    // Fetch products data from the API
    const response = await fetch('https://ohupfitness.com.tr/inc/get_products.php');

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const products = await response.json();

    // Get the cart from localStorage
    let cart = JSON.parse(localStorage.getItem('cart')) || [];

    // Update the cart with new product data
    cart = cart.map((cartItem) => {
      const product = products.find((p) => parseInt(p.id) === parseInt(cartItem.id)); // Make sure both ids are integers

      if (product) {
        // Convert both prices to numbers for comparison
        const newPrice = parseFloat(product.price);
        const currentPrice = parseFloat(cartItem.price);

        // Check if the price has changed
        if (newPrice !== currentPrice) {
          cartItem.price = newPrice.toString(); // Update the price in the cart
        }

        // Check if the product is still available
        if (parseInt(product.isAvailable) === 0) {
          return null; // Mark the item for removal if not available
        }
      }

      return cartItem;
    }).filter(item => item !== null); // Remove items that are no longer available


    // Save the updated cart to localStorage
    localStorage.setItem('cart', JSON.stringify(cart));

    // Emit an event to notify other components that the cart has been updated
    EventBus.emit('update-cart-count');

    // Trigger an update in the Header component
    this.$root.$emit('update-cart-count');
  } catch (error) {
    console.error('Error fetching products:', error);
  }
},
    toggleCart(event) {
      event.stopPropagation();
      this.isCartOpen = !this.isCartOpen;
    },
    toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    if (!this.isMobileMenuOpen) {
      this.showCorporateDropdown = false;
      this.showShopDropdown = false;
      this.showSupportDropdown = false;
      this.showProductsDropdown = false;
    }
  },
  toggleDropdown(menu) {
    if (menu === 'corporate') {
      this.showCorporateDropdown = !this.showCorporateDropdown;
      this.showShopDropdown = false;
      this.showSupportDropdown = false;
      this.showProductsDropdown = false;
    } else if (menu === 'shop') {
      this.showShopDropdown = !this.showShopDropdown;
      this.showCorporateDropdown = false;
      this.showSupportDropdown = false;
      this.showProductsDropdown = false;
    } else if (menu === 'destek') {
      this.showSupportDropdown = !this.showSupportDropdown;
      this.showCorporateDropdown = false;
      this.showShopDropdown = false;
      this.showProductsDropdown = false;
    } else if (menu === 'products') {
      this.showProductsDropdown = !this.showProductsDropdown;
      this.showShopDropdown = false;
      this.showCorporateDropdown = false;
      this.showSupportDropdown = false;
    }
  },
  handleClickOutside(event) {
    if (!this.$el.contains(event.target)) {
      this.showCorporateDropdown = false;
      this.showShopDropdown = false;
      this.showSupportDropdown = false;
      this.showProductsDropdown = false;
    }
  },
    loadCart() {
      this.cart = JSON.parse(localStorage.getItem('cart')) || [];
      this.updateCartCount();
    },
    updateCartCount() {
      this.cartCount = this.cart.length;
    },
    removeFromCart(item) {
  this.cart = this.cart.filter(cartItem => cartItem.id !== item.id || cartItem.color !== item.color);
  localStorage.setItem('cart', JSON.stringify(this.cart));
  this.updateCartCount();
},
    updateCart(item) {
      if (item.quantity < 1) item.quantity = 1;
      localStorage.setItem('cart', JSON.stringify(this.cart));
      this.updateCartCount();
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--;
        this.updateCart(item);
      }
    },
    increaseQuantity(item) {
      item.quantity++;
      this.updateCart(item);
    },
    redirectToCart() {
    this.$router.push('/cart');
  },
  redirectToCheckout() {
    this.$router.push('/checkout');
  }
  },
  watch: {
    cart: {
      handler() {
        this.updateCartCount();
      },
      deep: true,
    }
  }
};
</script>
<style scoped>
.top-bar {
  background-color: #000;
  color: white;
  padding: 1em;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  font-family: "League Spartan", sans-serif;

}

.top-bar-text {
  display: inline-block;
  position: absolute;
  animation: slide-in 4s linear infinite;
}

@keyframes slide-in {
  0% { transform: translateX(100%); opacity: 0; }
  10% { transform: translateX(0); opacity: 1; }
  90% { transform: translateX(0); opacity: 1; }
  100% { transform: translateX(-100%); opacity: 0; }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1em;
  border-bottom: 1px solid #ccc;
  margin-top: 30px;
  font-family: "League Spartan", sans-serif;
  font-weight: 500;
}

.hamburger-menu {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 200px;
}

nav {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  margin-right: 150px;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

nav ul li {
  margin-right: 1em;
}

nav ul li a {
  color: black;
  text-decoration: none;
}

nav ul li a:hover {
  color: #ffa100;
  transition: all 0.5s;
}

nav ul li a.shop-link {
  color: #846846;;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

nav ul li a.shop-link span {
  color: white;
  background-color: #ff0000;
  padding: 0.2em 0.5em;
  border-radius: 3px;
  margin-left: 0.3em;
  font-size: 11px;
}

nav ul li a.shop-link:hover span {
  background-color: #cc0000;
}

.icons {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-left: -30px;
  gap: 10px;
}

.icons a {
  color: black;
  text-decoration: none;
  position: relative;
}

.cart-count {
  background-color: #ffa100;
  color: white;
  border-radius: 50%;
  padding: 0.2em 0.5em;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 12px;
}

.cart-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  transform: translateX(100%);
  z-index: 100000;
}

.cart-sidebar-open {
  transform: translateX(0);
}

.cart-sidebar-closed {
  transform: translateX(100%);
}

.cart-header {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  border-bottom: 1px solid #ccc;
}

.cart-header h2 {
  margin: 0;
}

.cart-header button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

.cart-contents {
  padding: 1em;
  flex-grow: 1;
  overflow-y: auto;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1em;
}

.cart-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.cart-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.cart-item-info {
  flex: 1;
  margin-left: 1em;
}

.cart-item-info p {
  margin: 0;
  margin-bottom: 5px;
}

.quantity-selector {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantity-selector button {
  background-color: #000000;
  border: 1px solid #ccc;
  color: #fff;
  padding: 0.5em;
  cursor: pointer;
  font-size: 1em;
  width: 2em;
  text-align: center;
}

.quantity-selector input {
  text-align: center;
  width: 3em;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  padding: 0.5em;
  -moz-appearance: textfield;
  background-color: #000;
  color: #fff;
}

.quantity-selector input::-webkit-outer-spin-button,
.quantity-selector input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-item button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.cart-summary {
  text-align: center;
  border-top: 1px solid #ccc;
  padding-top: 1em;
  padding-bottom: 1em;
}

.cart-summary .subtotal {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
}

.cart-summary .total-price {
  font-size: 1.5em;
  margin: 0;
  font-weight: bold;
  color: #000;
}

.cart-summary .tax-info {
  font-size: 0.8em;
  color: #888;
  margin: 0.5em 0;
}

.cart-summary .buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.view-cart-button {
  background-color: transparent;
  border: 1px solid #ffa100;
  color: #ffa100;
  padding: 0.5em 1em;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  width: 48%;
}

.view-cart-button a{
  color: #ffa100;
  text-decoration: none;
}

.checkout-button a{
  color: #fff;
  text-decoration: none;
}

.checkout-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  width: 48%;
}


.cart-empty-icon {
  font-size: 4em;
}

.start-shopping {
  background-color: #ffa100;
  color: white;
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
}

.start-shopping a{
  text-decoration: none;
  color:white;
}

.cart-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2em;
  text-align: center;
  margin-top: 100px;
}

.cart-empty-icon {
  font-size: 5em;
  color: #ffa100;
  margin-bottom: 1em;
  transition: transform 0.3s ease;
}

.cart-empty-icon:hover {
  transform: scale(1.1);
}

.cart-empty-text {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.5em 0;
  color: #333;
}

.cart-suggestion {
  font-size: 1.2em;
  margin-bottom: 1.5em;
  color: #777;
}

.start-shopping {
  background-color: #ffa100;
  color: white;
  border: none;
  padding: 0.75em 1.5em;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.start-shopping:hover {
  background-color: #ff8a00;
}


.cart-item i.remove-icon {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1.2em;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
  }

  nav {
    display: none;
  }

  nav.mobile-menu-open {
    display: block;
    position: absolute;
    top: 12%;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height: auto;
  }

  nav ul {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul li {
    margin-right: 0;
    width: 100%;
  }

  nav ul li a {
    padding: 1em;
    width: 100%;
    display: block;
  }

  .dropdown-menu {
    display: none; /* Dropdown menüyü başlangıçta gizle */
    flex-direction: column;
    width: 100%;
    padding-left: 1em;
    position: relative !important;
    z-index:1000;
    margin-left: 0px !important;
  }

  .dropdown-menu li a {
    padding: 0.5em 0; /* Dropdown menüdeki linkler için padding ayarla */
  }

  nav ul li a[aria-expanded="true"] + .dropdown-menu {
    display: flex; /* Dropdown menüleri göster */
  }

  .cart-sidebar{
    width:100%
  }


}

@media (min-width: 769px) and (max-width:1024px) {
  .cart-sidebar{
    width: 100%;
  }
  .cart-empty{
    margin-left: 300px;
  }
}


.dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  overflow: hidden;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #bdbdbd;
  font-weight: 300;
  font-family: "League Spartan", sans-serif;
  margin-left: -20px;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.dropdown-menu.show {
  max-height: 200px; /* İçeriğe göre ayarlanabilir */
  opacity: 1;
}

.dropdown-menu li {
  padding: 5px;
  position: relative;
  margin-top: 5px;
}

.dropdown-menu li a {
  color: rgb(100, 100, 100);
  text-decoration: none;
  display: block;
  width: 100%;
  position: relative;
  padding-left: 20px;
  padding-bottom: 5px;
  transition: color 0.3s;
  text-align: left;
}

.dropdown-menu li a::after {
  content: '';
  position: absolute;
  left: 20px;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #ffa100;
  transition: width 0.3s;
}

.dropdown-menu li a:hover {
  color: #ffa100;
  font-weight: 400;
}

.dropdown-menu li a:hover::after {
  width: calc(100% - 20px);
}

.shop-link i {
  margin-left: 5px;
  transition: transform 0.3s;
}

/* Modern Search Bar Styles */
.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.search-container {
  width: 50%;
  max-width: 800px;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.search-container input {
  width: 100%;
  border: none;
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 50px;
  outline: none;
  background-color: #f1f1f1;
  transition: background-color 0.3s ease;
}

.search-container input:focus {
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search-container .close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  margin-left: 15px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.search-container .close-btn:hover {
  color: #ff5757;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}



</style>
